import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // Import Framer Motion

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
};

const HomePage = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/LRU");
  };

  return (
    <div className="parent-home">
      <div className="homepage-container">
        <main className="homepage-main">
          <motion.section
            className="homepage-section"
            initial="initial"
            whileInView="animate"
            variants={fadeInUp}
          >
            <button className="homepage-button" onClick={handleGetStarted}>
              Get Started
            </button>
          </motion.section>
        </main>
      </div>
    </div>
  );
};

export default HomePage;
