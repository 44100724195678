import React, { useEffect, useState } from "react";
import Loading from "../loading";
import {
  db,
  getProjectByIdRealTime,
  getUserByIdRealTime,
  setProjectById,
} from "../firebase";
import { Box, Divider, Flex } from "@chakra-ui/react";
import {
  MDBBtn,
  MDBInput,
  MDBValidation,
  MDBValidationItem,
} from "mdb-react-ui-kit";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import useCurrentUser from "../currentUser/currentuser";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";

const DetailsChat = ({ leaderId, clientId, projectId }) => {
  const [leader, setLeader] = useState(null);
  const [client, setClient] = useState(null);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [exist, setExist] = useState(false);

  const userData = useCurrentUser();
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");
  const [fixAmount, setFixAmount] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    let unsubscribeLeader, unsubscribeClient, unsubscribeProject;

    // Reset states to null when new chat is selected to avoid stale data
    setLeader(null);
    setClient(null);
    setProject(null);
    setLoading(true);

    // Fetch leader, client, and project in real-time
    unsubscribeLeader = getUserByIdRealTime(leaderId, setLeader);
    unsubscribeClient = getUserByIdRealTime(clientId, setClient);
    unsubscribeProject = getProjectByIdRealTime(projectId, setProject);

    return () => {
      unsubscribeLeader?.();
      unsubscribeClient?.();
      unsubscribeProject?.();
    };
  }, [leaderId, clientId, projectId]);

  // Check if all data is loaded
  useEffect(() => {
    if (leader && client && project) {
      setExist(true);
      setMinAmount(project.minAmount);
      setMaxAmount(project.maxAmount);
      setFixAmount(project.fixAmount);
      setLoading(false);
    } else {
      setExist(false);
      setLoading(false);
    }
  }, [leader, client, project]);

  const handleSave = async () => {
    try {
      await setProjectById(projectId, parseFloat(fixAmount));
      setProject({
        ...project,
        fixAmount: parseFloat(fixAmount),
      });
      setFixAmount(project.fixAmount);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating project details: ", error);
    }
  };

  const handleCancel = () => {
    setFixAmount(project.fixAmount);
    setIsEditing(false);
  };

  const handleCancelProject = async () => {
    const batch = writeBatch(db);

    try {
      // 1. Query the relevant chat document(s) by projectId
      const chatsRef = collection(db, "chats");
      const chatsQuery = query(chatsRef, where("projectId", "==", projectId));
      const chatDocs = await getDocs(chatsQuery);

      // 2. Update chat documents to set active: false
      chatDocs.forEach((chatDoc) => {
        batch.update(chatDoc.ref, { active: false });
      });

      // 3. Update the project document to change its work status and reset the leader
      const projectRef = doc(db, "projects", projectId);
      batch.update(projectRef, {
        work: "still",
        status: "waiting",
        leaderId: "",
        leader: "",
      });

      // 4. Update the leader's status in the 'users' collection to working: false
      const leaderId = chatDocs.docs[0].data().leaderId; // Assuming only one leader per project
      const leaderRef = doc(db, "users", leaderId);
      batch.update(leaderRef, { working: false });

      // 5. Decrement AProjects for the user who made the project
      const userRef = doc(db, "users", clientId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const currentAProjects = userSnap.data().AProjects || 0;
        const newAProjects = Math.max(0, currentAProjects - 1); // Ensure it doesn't go below 0
        batch.update(userRef, { AProjects: newAProjects });
      }

      // Commit the batch update
      await batch.commit();
    } catch (error) {
      console.error("Error updating project and related data: ", error);
    }
  };

  if (loading) return <Loading />;
  if (!exist && !loading) return <>No data available</>;

  return (
    <>
      <div className="chat-list-header">
        <h4>About</h4>
      </div>

      <div className="details-chat-section">
        <span className="details-project-body">
          <span className="details-project-subject">Client:</span>
          {client.firstName} {client.lastName}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Leader:</span>
          {leader.firstName} {leader.lastName}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Subject:</span>
          {project.subject}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Body:</span>
          {project.body}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Date:</span>
          {new Date(
            project.publicationDate.seconds * 1000 +
              project.publicationDate.nanoseconds / 1000000
          ).toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Amount:</span>
          Max: {project.maxAmount}
          <br />
          Min: {project.minAmount}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Fix Amount:</span>
          {project.fixAmount || "-"}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Status:</span>
          {project.status}
        </span>
        <span className="details-project-body">
          <span className="details-project-subject">Work:</span>
          {project.work}
        </span>
      </div>
      <Box className="notificationsDivider">
        <Divider orientation="horizontal" borderColor="transparent" />
      </Box>
      <div className="details-chat-footer">
        <div className="editableDiv">
          <MDBValidation className="row g-3" isValidated>
            <MDBValidationItem
              className="col-md-6"
              feedback={`The minimum amount should be ${minAmount} - The maximum amount should be ${maxAmount}.`}
              invalid
            >
              <MDBInput
                label="Fix Amount"
                labelStyle={{ color: "#fff" }}
                type="number"
                size="sm"
                autoComplete="off"
                id="validationCustom03"
                disabled={!isEditing}
                required
                max={maxAmount}
                min={minAmount}
                value={fixAmount}
                name="Min Amount"
                width="10%"
                onChange={(e) => setFixAmount(e.target.value)}
              />
            </MDBValidationItem>
          </MDBValidation>
          {!isEditing &&
          userData.role === "client" &&
          project.start === false &&
          project.status === "approved" ? (
            <MDBBtn
              style={{ width: "max-content" }}
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              Edit
            </MDBBtn>
          ) : userData.role === "client" &&
            userData.uid === clientId &&
            project.status === "approved" ? (
            <Flex justifyContent="center" alignItems="center" gap="5px">
              <MDBBtn
                style={{ width: "max-content" }}
                outline
                size="sm"
                color="success"
                onClick={handleSave}
              >
                Save
              </MDBBtn>
              <MDBBtn
                outline
                style={{ height: "max-content" }}
                size="sm"
                color="danger"
                onClick={handleCancel}
              >
                Cancel
              </MDBBtn>
            </Flex>
          ) : (
            userData.role === "groupleader" &&
            userData.uid === leaderId &&
            project.start === false &&
            project.status === "approved" && (
              <Flex justifyContent="flex-end" alignItems="center" gap="5px">
                <MDBBtn
                  size="sm"
                  className="leadercheck"
                  tag="a"
                  color="success"
                  outline
                >
                  <IoCheckmarkOutline />
                </MDBBtn>
                <MDBBtn
                  size="sm"
                  onClick={() => {
                    handleCancelProject();
                  }}
                  className="leadercheck"
                  tag="a"
                  color="danger"
                  outline
                >
                  <IoMdClose />
                </MDBBtn>
              </Flex>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default DetailsChat;
